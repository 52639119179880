import { ENVIRONMENT_NAME } from '../../config/Environment';
import { ApiGatewayServer } from '../../server/apiGateway';
import { getOrCreateApiSessionsServer } from '../../server/apiSessions';
import { getErrorHandler } from '../../server/createAxiosInstance';
import type { FITTemplate } from '../quality-fit-templates/types';
import type {
	Measure,
	MeasureAuditEvent,
	MeasureFilters,
	SaveFileTemplateRequest,
	Status,
	StatusGroup,
	UpdateMeasureRequest,
	GetMeasureResponse,
	Highlight,
} from './shared/types';
import Axios, { type AxiosResponse, type CancelTokenSource } from 'axios';
import camelcaseKeys from 'camelcase-keys';

// eslint-disable-next-line no-restricted-syntax -- Legacy code
class QualityServer extends ApiGatewayServer {
	getMeasuresCancelToken: CancelTokenSource | null = null;
	getHighlightsCancelToken: CancelTokenSource | null = null;

	camelCaseResponse = (obj: any) => {
		return camelcaseKeys(obj, { deep: true, exclude: [/.* .*/, /.*-.*-.*-.*/] });
	};

	_baseRequest = async <T = unknown>(
		path: string,
		data?: Record<string, unknown>,
		cancelToken?: CancelTokenSource,
		isLocal?: boolean,
		camelCaseKeys = true,
	): Promise<AxiosResponse<T> | null> => {
		const authInfo = this.getAuthInfo(path, this.authStore.getToken);
		if (authInfo.isRefresh) {
			return null;
		}

		if (authInfo.isValid) {
			const config = {
				headers: {
					'content-type': 'application/json',
					...authInfo.headers,
				},
				...(cancelToken ? { cancelToken: cancelToken.token } : {}),
			};

			let resPromise: Promise<AxiosResponse<T>> | null = null;
			if (window.location.host === 'files-qa.navina.ai') {
				if (!data) {
					data = {};
				}

				data = {
					...data,
					qa_branch: 'files-qa',
				} as const as Record<string, unknown>;
			}

			if (ENVIRONMENT_NAME === 'jsonServer') {
				resPromise = this.server.get(authInfo.path, config);
			} else if (isLocal) {
				resPromise = this.localServer.post(authInfo.path, JSON.stringify(data), config);
			} else {
				resPromise = this.server.post(authInfo.path, JSON.stringify(data), config);
			}

			if (camelCaseKeys) {
				return resPromise.then(this.camelCaseResponse);
			}

			return resPromise;
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	getMeasures = async (sortAndFilters: any): Promise<AxiosResponse> => {
		if (this.getMeasuresCancelToken) {
			this.getMeasuresCancelToken.cancel('NewerRequestArrived');
			console.log('getMeasuresCancelToken cancelled');
		}

		this.getMeasuresCancelToken = Axios.CancelToken.source();
		return this._baseRequest('quality/getMeasures', sortAndFilters, this.getMeasuresCancelToken);
	};

	getMeasuresWithoutFilters = async (sortAndFilters: any): Promise<AxiosResponse> => {
		return this._baseRequest('quality/getMeasures', sortAndFilters);
	};

	getAppointments = async (nids: string[]): Promise<AxiosResponse> => {
		return this._baseRequest('quality/getAppointments', { nids });
	};

	getMeasure = async (measureId: string): Promise<AxiosResponse<GetMeasureResponse>> => {
		return this._baseRequest('quality/getMeasure', { id: measureId });
	};

	getMeasureTimeline = async (measureId: string): Promise<AxiosResponse<MeasureAuditEvent[]>> => {
		return this._baseRequest('quality/getMeasureTimeline', { id: measureId });
	};

	getPatientMeasures = async (
		NID: string,
		reportingPeriod: string,
		SID: string,
	): Promise<AxiosResponse<{ measures: ReadonlyArray<Measure> }>> => {
		return this._baseRequest('quality/getPatientMeasures', {
			NID,
			SID,
			reporting_period: reportingPeriod,
		});
	};

	getPatientPayerMeasures = async (NID: string, reportingPeriod: string): Promise<AxiosResponse<Measure[]>> => {
		return this._baseRequest('quality/getPatientPayerMeasures', {
			NID,
			reporting_period: reportingPeriod,
		});
	};

	updateMeasure = async (measureRequest: UpdateMeasureRequest): Promise<AxiosResponse> => {
		return this._baseRequest('quality/updateMeasure', { ...measureRequest });
	};

	runResetDemoEnv = async (): Promise<AxiosResponse> => {
		return this._baseRequest('quality/runResetDemoEnv', {});
	};

	getMeasureFilters = async (dataSourceId: number | number[]): Promise<AxiosResponse<{ filters: MeasureFilters }>> => {
		return this._baseRequest('quality/getMeasureFilters', { data_source_id: dataSourceId }, null);
	};

	getStatusesAndGroups = async (
		dataSourceId: number,
	): Promise<AxiosResponse<{ statuses: Status[]; groups: StatusGroup[] }>> => {
		return this._baseRequest('quality/getStatusesAndGroups', { data_source_id: dataSourceId });
	};

	saveFileTemplate = async (request: SaveFileTemplateRequest): Promise<AxiosResponse> => {
		return this._baseRequest('quality/saveFileTemplate', { ...request }, null);
	};

	getPayers = async (): Promise<AxiosResponse<{ payers: string[] }>> => {
		return this._baseRequest('quality/getPayers');
	};

	getTemplates = async (): Promise<AxiosResponse<{ templates: FITTemplate[] }>> => {
		const requestPromise = this._baseRequest<{ templates: FITTemplate[] }>(
			'quality/getTemplates',
			null,
			null,
			false,
			false,
		);

		requestPromise.then((response): void => {
			response.data.templates.forEach((template: FITTemplate): void => {
				template.insertion_timestamp = template.insertion_timestamp ? new Date(template.insertion_timestamp) : null;
			});
		});

		return requestPromise;
	};

	getLobsList = async (): Promise<AxiosResponse<{ readonly lobs: string[] }>> => {
		return this._baseRequest('quality/getLobs', null, null, false, false);
	};

	getPlansList = async (): Promise<AxiosResponse<{ readonly plans: string[] }>> => {
		return this._baseRequest('quality/getPlans', null, null, false, false);
	};

	downloadMissingPatientsReport = (
		dataSourceId: string,
		s3Bucket: string,
		s3Key: string,
	): Promise<AxiosResponse<{ url: string }>> => {
		return this._baseRequest('quality/downloadMissingPatientsReport', {
			data_source_id: dataSourceId,
			s3_file_path: s3Key,
			bucket_name: s3Bucket,
		});
	};

	getHighlights = async (dataSourceId: number): Promise<AxiosResponse<{ highlights: Highlight[] }>> => {
		if (this.getHighlightsCancelToken) {
			this.getHighlightsCancelToken.cancel('NewerRequestArrived');
			console.log('getHighlightsCancelToken cancelled');
		}

		this.getHighlightsCancelToken = Axios.CancelToken.source();
		return this._baseRequest('quality/getHighlights', { data_source_id: dataSourceId });
	};
}

const getSuccessHandler = () => (response: AxiosResponse) => response;

let maybeQualityServerApi: QualityServer | null = null;

function createQualityServerApi(): QualityServer {
	const qualityServerApi = new QualityServer();

	qualityServerApi.server.interceptors.response.use(
		getSuccessHandler(),
		getErrorHandler(qualityServerApi.server, getOrCreateApiSessionsServer()),
	);

	return qualityServerApi;
}

export function getOrCreateQualityServerApi(): QualityServer {
	if (maybeQualityServerApi === null) {
		maybeQualityServerApi = createQualityServerApi();
	}
	return maybeQualityServerApi;
}
