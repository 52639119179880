import { intArraysEqual } from '../pages/common/utils';
import { getOrCreateApiGateway } from '../server/apiGateway';
import type { ScheduleFilter, SummaryRecord } from '../types';
import { getOrCreateAnalytics } from '../utils/analytics';
import { browserHistory } from '../utils/history';
import { getOrCreateLogger } from '../utils/logger';
import { type AuthStore } from './authStore';
import { NavinaCache } from './cache';
import { observable, action, configure, autorun, runInAction, makeObservable } from 'mobx';
import QueryString from 'query-string';
import { useContext, createContext } from 'react';

configure({ enforceActions: 'always' });

interface ClinicsDepartmentsProvidersType {
	readonly clinics: ReadonlyArray<{ readonly id: number; readonly name: string }>;
	readonly departments: ReadonlyArray<{ readonly id: number; readonly name: string; readonly clinicId: number }>;
	readonly providers: ReadonlyArray<{
		readonly id: number;
		readonly username: string;
		readonly clinicId: number;
		readonly firstName: string;
		readonly lastName: string;
		readonly departmentIds: ReadonlyArray<string>;
	}>;
	readonly insuranceGroups: ReadonlyArray<{
		readonly id: number;
		readonly dataSourceId: number;
		readonly name: string;
	}>;
}

type SummariesResult = ReadonlyArray<SummaryRecord>;

const getSummariesListSchedule = async (
	date: string,
	scheduleFilter: ScheduleFilter,
	patientLookup?: string,
): Promise<SummariesResult> => {
	const response = await getOrCreateApiGateway().getSummariesListSchedule(date, scheduleFilter, patientLookup);

	if (Array.isArray(response?.data)) {
		return response.data;
	}

	return null;
};

const getSummariesListCurrentDay = async (
	date: string,
	scheduleFilter: ScheduleFilter,
	patientLookup?: string,
): Promise<SummariesResult | null> => {
	const response = await getOrCreateApiGateway().getSummariesListCurrentDay(date, scheduleFilter, patientLookup);

	if (Array.isArray(response?.data)) {
		return response.data;
	}

	return null;
};

export enum TimeSpanType {
	today = 'Today',
	custom = 'Custom',
	none = 'None',
}

export class ScheduleStore {
	currentDate: string | undefined = undefined;
	currentDateSummaryRecords: ReadonlyArray<SummaryRecord> = [];
	summaryRecords: ReadonlyArray<SummaryRecord> = [];
	isoDate: string | undefined = undefined;
	patientLookup: string | undefined = undefined;
	timeSpanType: TimeSpanType = TimeSpanType.today;
	customDate = new Date();
	currentPage = 0;
	loading = false;
	isErrorState = false;
	isEmptySchedule = false;

	clinicsDepartmentsProvidersSource: ClinicsDepartmentsProvidersType = {
		clinics: [],
		departments: [],
		providers: [],
		insuranceGroups: [],
	};

	clinicsDepartmentsProviders: ClinicsDepartmentsProvidersType = this.clinicsDepartmentsProvidersSource;
	selectedClinicId: number | undefined = undefined;
	selectedDepartmentId: number | undefined = undefined;
	selectedProviderIds: number[] = [];
	selectedInsuranceFilterGroups: number[] = [];

	constructor(authStore: AuthStore | undefined, shouldLoad: boolean) {
		makeObservable(this, {
			currentDate: observable,
			currentDateSummaryRecords: observable,
			summaryRecords: observable,
			isoDate: observable,
			patientLookup: observable,
			timeSpanType: observable,
			customDate: observable,
			currentPage: observable,
			loading: observable,
			isErrorState: observable,
			isEmptySchedule: observable,
			clinicsDepartmentsProviders: observable,
			selectedClinicId: observable,
			selectedDepartmentId: observable,
			selectedProviderIds: observable,
			selectedInsuranceFilterGroups: observable,
			setClinicsDepartmentsProviders: action,
			setCurrentDate: action,
			setSelectedClinicId: action,
			setSelectedDepartmentId: action,
			setSelectedProviderIds: action,
			setSelectedInsuranceFilterGroups: action,
			setCurrentDateSummaryRecords: action,
			setISODate: action,
			setPatientLookup: action,
			setLoading: action,
			setSummaryRecords: action,
			setIsErrorState: action,
			setIsEmptySchedule: action,
			setTimeSpanType: action,
			setCustomDate: action,
			setCurrentPage: action,
		});

		if (!authStore) {
			getOrCreateLogger().error('no authStore, scheduleStore initialization failed');
			return;
		}

		const parsedQueries = QueryString.parse(browserHistory.location.search);
		const queryTimeSpan = parsedQueries.timeSpan;

		if (queryTimeSpan) {
			try {
				runInAction((): void => {
					const queryTimeSpanType = TimeSpanType[(queryTimeSpan as string).toLowerCase()];
					if (queryTimeSpanType) {
						this.setTimeSpanType(queryTimeSpanType);
						if (queryTimeSpanType === TimeSpanType.custom) {
							const customDate = new Date(parsedQueries.customDate as string);
							if (customDate.toString() !== 'Invalid Date') {
								this.setCustomDate(customDate);
							}
						}
					}
				});
			} catch (err) {
				console.error('Unrecognized TimeSpan in query: ', queryTimeSpan, err);
			}
		}

		const analytics = getOrCreateAnalytics();

		autorun((): void => {
			if (!authStore.getToken) {
				console.log('no token, user not yet authenticated');
				return;
			}

			if (NavinaCache.exist('selectedClinicId')) {
				const id = parseInt(NavinaCache.get('selectedClinicId'), 10);
				this.setSelectedClinicId(id);
			}

			if (NavinaCache.exist('selectedDepartmentId')) {
				const id = parseInt(NavinaCache.get('selectedDepartmentId'), 10);
				this.setSelectedDepartmentId(id);
			}

			if (NavinaCache.exist('selectedProviderIds')) {
				const ids: number[] = NavinaCache.getArray('selectedProviderIds').map((str) => parseInt(str, 10));
				this.setSelectedProviderIds(ids);
			}

			if (NavinaCache.exist('selectedInsuranceFilterGroups')) {
				const ids: number[] = NavinaCache.getArray('selectedInsuranceFilterGroups').map((str) => parseInt(str, 10));
				this.setSelectedInsuranceFilterGroups(ids);
			}
		});

		autorun((): void => {
			if (!authStore.getToken) {
				console.log('no token, user not yet authenticated');
				return;
			}

			if (!shouldLoad) {
				console.log('Should not load schedule store');
				return;
			}

			this.setLoading(true);
			getOrCreateApiGateway()
				.getClinicsDepartmentsProviders()
				.then((response): void => {
					if (response.data) {
						this.setClinicsDepartmentsProviders(response.data);
						this.clinicsDepartmentsProvidersSource = response.data;
						this.filterResults();
					}
				});
		});

		autorun((): void => {
			if (!authStore.getToken) {
				console.log('no token, user not yet authenticated');
				return;
			}

			if (!this.currentDate) {
				return;
			}

			if (!shouldLoad) {
				console.log('Should not load schedule store');
				return;
			}

			getSummariesListCurrentDay(this.currentDate, {
				selectedClinicId: this.selectedClinicId,
				selectedDepartmentId: this.selectedDepartmentId,
				selectedProviderIds: this.selectedProviderIds,
				selectedInsuranceFilterGroups: this.selectedInsuranceFilterGroups,
			} as ScheduleFilter)
				.then((result: SummariesResult | null): void => {
					if (result) {
						this.setCurrentDateSummaryRecords(result);
					}
				})
				.catch((err): void => {
					getOrCreateLogger().error('Error loading currentDate summaries list', err);
					this.setCurrentDateSummaryRecords([]);
					this.setLoading(false);
					this.setIsErrorState(true);
					console.log(err);
				});
		});

		autorun(
			(): void => {
				if (!authStore.getToken) {
					console.log('no token, user not yet authenticated');
					return;
				}

				if (!shouldLoad) {
					console.log('Should not load schedule store');
					return;
				}

				const logExtras = { date: this.isoDate, patientLookup: this.patientLookup };

				this.setLoading(true);

				getSummariesListSchedule(
					this.isoDate,
					{
						selectedClinicId: this.selectedClinicId,
						selectedDepartmentId: this.selectedDepartmentId,
						selectedProviderIds: this.selectedProviderIds,
						selectedInsuranceFilterGroups: this.selectedInsuranceFilterGroups,
					} as ScheduleFilter,
					this.patientLookup,
				)
					.then((result): void => {
						if (result) {
							this.setSummaryRecords(result);
							console.log('Summaries list success', { totalCount: result.length, ...logExtras });
							this.setLoading(false);
							this.setIsErrorState(false);
							this.setIsEmptySchedule(false);
						} else if (this.clinicsDepartmentsProvidersSource.providers.length === 0) {
							this.setLoading(false);
							this.setIsErrorState(false);
							this.setIsEmptySchedule(true);
							analytics.track(analytics.idsNames.NoAccessPageView);
						}
					})
					.catch((err): void => {
						getOrCreateLogger().error('Error loading summaries list', err);
						console.log('Summaries list error', logExtras);
						this.setSummaryRecords([]);
						this.setLoading(false);
						this.setIsErrorState(true);
						console.log(err);
					});
			},
			{ delay: 500 },
		);

		autorun((): void => {
			const isoNow = new Date().toISOString();

			switch (this.timeSpanType) {
				case TimeSpanType.today:
					this.setISODate(isoNow);
					break;
				case TimeSpanType.custom: {
					this.setISODate(this.customDate.toISOString());
					break;
				}
				default:
					this.setTimeSpanType(TimeSpanType.none);
			}
		});
	}

	setClinicsDepartmentsProviders = (cdp: ClinicsDepartmentsProvidersType): void => {
		this.clinicsDepartmentsProviders = cdp;

		// Make sure the current selected Clinic / Department / Provider are within our permission boundaries
		if (this.selectedClinicId) {
			if (!cdp.clinics.find((clinic): boolean => clinic.id === this.selectedClinicId)) {
				this.setSelectedClinicId(undefined, true);
			}
		}

		if (this.selectedDepartmentId) {
			if (!cdp.departments.find((department): boolean => department.id === this.selectedDepartmentId)) {
				this.setSelectedDepartmentId(undefined, true);
			}
		}
		// todo: take care of SelectedProvider and Providers
	};

	setCurrentDate = (currentDate: string): void => {
		this.currentDate = currentDate;
	};

	updateCache = (): void => {
		NavinaCache.set('selectedClinicId', this.selectedClinicId);
		NavinaCache.set('selectedDepartmentId', this.selectedDepartmentId);
		NavinaCache.set('selectedProviderIds', this.selectedProviderIds);
		NavinaCache.set('selectedInsuranceFilterGroups', this.selectedInsuranceFilterGroups);
	};

	setSelectedClinicId = (selectedClinicId: typeof this.selectedClinicId, manuallyUpdate = false): void => {
		if (this.selectedClinicId !== selectedClinicId) {
			this.selectedClinicId = selectedClinicId;

			if (manuallyUpdate) {
				this.filterResults();
				this.updateCache();
			}
		}
	};

	setSelectedDepartmentId = (selectedDepartmentId: typeof this.selectedDepartmentId, manuallyUpdate = false): void => {
		if (this.selectedDepartmentId !== selectedDepartmentId) {
			this.selectedDepartmentId = selectedDepartmentId;

			if (manuallyUpdate) {
				this.filterResults();
				this.updateCache();
			}
		}
	};

	setSelectedProviderIds = (selectedProviderIds: typeof this.selectedProviderIds, manuallyUpdate = false): void => {
		if (!intArraysEqual(this.selectedProviderIds, selectedProviderIds)) {
			this.selectedProviderIds = selectedProviderIds;

			if (manuallyUpdate) {
				this.updateCache();
			}
		}
	};

	setSelectedInsuranceFilterGroups = (
		selectedInsuranceFilterGroups: typeof this.selectedInsuranceFilterGroups,
		manuallyUpdate = false,
	): void => {
		if (!intArraysEqual(this.selectedInsuranceFilterGroups, selectedInsuranceFilterGroups)) {
			this.selectedInsuranceFilterGroups = selectedInsuranceFilterGroups;

			if (manuallyUpdate) {
				this.updateCache();
			}
		}
	};

	setCurrentDateSummaryRecords = (currentDateSummaryRecords: typeof this.currentDateSummaryRecords): void => {
		this.currentDateSummaryRecords = currentDateSummaryRecords;
	};

	setISODate = (isoDate: typeof this.isoDate): void => {
		this.isoDate = isoDate;
		this.patientLookup = undefined;
	};

	setPatientLookup = (patientLookup: typeof this.patientLookup): void => {
		if (!patientLookup) {
			this.patientLookup = undefined;
			this.setTimeSpanType(TimeSpanType.today);
			return;
		}
		this.patientLookup = patientLookup;
		this.timeSpanType = TimeSpanType.none;
		this.isoDate = undefined;
	};

	setLoading = (loading: typeof this.loading): void => {
		this.loading = loading;
	};

	setSummaryRecords = (summaryRecords: typeof this.summaryRecords): void => {
		this.summaryRecords = summaryRecords;
	};

	setIsErrorState = (isErrorState: typeof this.isErrorState): void => {
		this.isErrorState = isErrorState;
	};

	setIsEmptySchedule = (isEmptySchedule: typeof this.isEmptySchedule): void => {
		this.isEmptySchedule = isEmptySchedule;
	};

	setTimeSpanType = (timeSpanType: typeof this.timeSpanType): void => {
		this.timeSpanType = timeSpanType;
	};

	setCustomDate = (customDate: Date): void => {
		this.customDate = customDate;
	};

	setCurrentPage = (currentPage: typeof this.currentPage): void => {
		this.currentPage = currentPage;
	};

	filterResults = (): void => {
		// TODO - can extract to config
		const hideEmptyDepartments = true;
		const hideFullNamelessProviders = true;

		let departments = [...this.clinicsDepartmentsProvidersSource.departments];
		let providers = [...this.clinicsDepartmentsProvidersSource.providers];
		let insuranceFilterGroups = [...this.clinicsDepartmentsProvidersSource.insuranceGroups];

		if (hideFullNamelessProviders) {
			providers = providers.filter((provider): boolean => Boolean(provider.firstName || provider.lastName));
		}

		if (hideEmptyDepartments) {
			departments = departments.filter(
				(department): boolean =>
					providers.filter((provider): boolean => provider.departmentIds.includes(department.id.toString())).length > 0,
			);
		}

		if (this.selectedClinicId) {
			departments = departments.filter((department): boolean => department.clinicId === this.selectedClinicId);

			providers = providers.filter((provider): boolean => provider.clinicId === this.selectedClinicId);

			insuranceFilterGroups = insuranceFilterGroups.filter(
				(insuranceFilterGroup): boolean => insuranceFilterGroup.dataSourceId === this.selectedClinicId,
			);
		}

		if (this.selectedDepartmentId) {
			const departmentIdString = String(this.selectedDepartmentId);
			providers = providers.filter((provider): boolean => provider.departmentIds.includes(departmentIdString));
		}

		this.setClinicsDepartmentsProviders({
			clinics: this.clinicsDepartmentsProvidersSource.clinics,
			departments,
			providers,
			insuranceGroups: insuranceFilterGroups,
		});

		// Reset the current selected department or provider if not appears on new <option> elements
		if (!departments.find((department): boolean => department.id === this.selectedDepartmentId)) {
			this.setSelectedDepartmentId(undefined, true);
		}

		const filteredProviders: number[] = providers
			.filter((provider): boolean => this.selectedProviderIds.includes(provider.id))
			.map((provider) => provider.id);

		this.setSelectedProviderIds(filteredProviders);

		const filteredInsuranceFilterGroups: number[] = insuranceFilterGroups
			.filter((group): boolean => this.selectedInsuranceFilterGroups.includes(group.id))
			.map((insuranceFilterGroup) => insuranceFilterGroup.id);

		this.setSelectedInsuranceFilterGroups(filteredInsuranceFilterGroups);
	};
}

export const ScheduleStoreContext = createContext<ScheduleStore>(null);
export const useScheduleStore = (): ScheduleStore => useContext(ScheduleStoreContext);
