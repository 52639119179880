import { ErrorBoundary } from './ErrorBoundary';
import { NotFoundMessage } from './GeneralMessage';
import { addFavicon } from './favicon';
import './main.scss';
import { NavinaLoginStep } from './pages/auth/steps/NavinaLoginStepEnum';
import { AddTemplate } from './pages/quality-files-ingestion-tool/AddTemplate';
import { VeradigmLogin } from './pages/veradigm/login';
import { AnyoneRoute } from './react-router/auth/AnyoneRoute';
import { ExternalRedirectRoute } from './react-router/auth/ExternalRedirectRoute';
import { GuestOnlyRoute } from './react-router/auth/GuestOnlyRoute';
import { ProtectedRoute } from './react-router/auth/ProtectedRoute';
import { AuthLayout } from './react-router/layouts/AuthLayout';
import { EmptyLayout } from './react-router/layouts/EmptyLayout';
import { SpinnerLayout } from './react-router/layouts/SpinnerLayout';
import { SummaryLayout } from './react-router/layouts/SummaryLayout';
import './theme/antd-tokens/light.scss';
import { GlobalStyle } from './theme/globalStyle';
import { slownessMeasure } from './utilities/performances/SlownessTask';
import { toKebabCase } from './utilities/styling/NamingFormatter';
import { browserHistory } from './utils/history';
import { NavinaDataProvider } from './utils/useNavinaData';
import { theme } from 'antd';
import 'normalize.css';
import { lazy } from 'react';
import { Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

const NotFoundPage = lazy(() =>
	import(/* webpackChunkName: "NotFound" */ './pages/not-found/NotFound').then((module) => ({
		default: module.NotFound,
	})),
);

const EpicLogin = lazy(() => import(/* webpackChunkName: "EpicLogin" */ './pages/epic/login'));

const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ './pages/auth/Logout'));

const LoginForm = lazy(() => import(/* webpackChunkName: "LoginForm" */ './pages/auth/LoginForm'));

const SummaryPage = lazy(() => import(/* webpackChunkName: "SummaryPage" */ './pages/summary/SummaryPage'));

const PatientsListPage = lazy(
	() => import(/* webpackChunkName: "PatientsListPage" */ './pages/patients-list/PatientsListPage'),
);

const DocumentViewerFullPage = lazy(() =>
	import(/* webpackChunkName: "DocumentViewerFullPage" */ './pages/summary/DocViewer/DocumentViewerFullPage').then(
		(module) => ({
			default: module.DocumentViewerFullPage,
		}),
	),
);

const QuicksightPage = lazy(() => import(/* webpackChunkName: "QuicksightPage" */ './pages/quicksight/QuicksightPage'));

const PostVisitPage = lazy(() => import(/* webpackChunkName: "PostVisitPage" */ './pages/post-visit/PostVisitPage'));

const AntdDemo = lazy(() => import(/* webpackChunkName: "AntdDemo" */ './pages/antd-demo/AntdDemo'));

const Quality = lazy(() => import(/* webpackChunkName: "Quality" */ './pages/quality/care-team/PageContainer'));

const FileIngestionToolPage = lazy(() =>
	import(
		/* webpackChunkName: "QualityFilesIngestionToolPageContainer" */ './pages/quality-files-ingestion-tool/PageContainer'
	).then((module) => ({
		default: module.FileIngestionToolPage,
	})),
);

const FilesManager = lazy(
	() => import(/* webpackChunkName: "FileManagerPageContainer" */ './pages/file-manager/PageContainer'),
);

const FITTemplatesPage = lazy(() =>
	import(/* webpackChunkName: "QualityFitTemplatesPageContainer" */ './pages/quality-fit-templates/PageContainer').then(
		(module) => ({
			default: module.FITTemplatesPage,
		}),
	),
);

const getCssVars = (): string => {
	return Object.keys(theme.useToken().token)
		.map((token): string => {
			const tokenValue = theme.useToken().token[token];
			const maybePixel = !Number.isNaN(parseInt(tokenValue, 10)) ? 'px' : '';

			return `--antd-${toKebabCase(token)}: ${tokenValue}${maybePixel};`;
		})
		.join('\n');
};

const EmptyDiv = () => <div />;

export function App() {
	slownessMeasure.timeLog('React has started');
	addFavicon();
	const { token } = theme.useToken();

	return (
		<ErrorBoundary>
			<ThemeProvider theme={{ antd: token }}>
				<ToastContainer position='top-center' autoClose={5_000} hideProgressBar={true} />
				<GlobalStyle rootVariables={getCssVars()} />
				<Router history={browserHistory}>
					<NavinaDataProvider>
						<Switch>
							<GuestOnlyRoute exact path='/sso' layout={AuthLayout} component={LoginForm} />
							<GuestOnlyRoute path='/sso/:nextPath+' layout={AuthLayout} component={LoginForm} />
							<AnyoneRoute path='/epic/login' layout={SpinnerLayout} component={EpicLogin} spinningForever={true} />
							<AnyoneRoute
								path='/overlay/epic/login'
								layout={SpinnerLayout}
								component={EpicLogin}
								spinningForever={true}
							/>
							<AnyoneRoute path='/veradigm/launch' layout={SpinnerLayout} component={VeradigmLogin} />
							<GuestOnlyRoute path='/login/:nextPath+' layout={SpinnerLayout} component={LoginForm} />
							<GuestOnlyRoute exact path='/login' layout={SpinnerLayout} component={LoginForm} />
							<GuestOnlyRoute exact path='/overlay/login' layout={SpinnerLayout} component={LoginForm} />
							<ProtectedRoute
								exact
								path='/auth/changePassword'
								defaultStepId={NavinaLoginStep.ChangePassword}
								layout={AuthLayout}
								component={LoginForm}
							/>
							<GuestOnlyRoute exact path='/authenticatedUrl' layout={AuthLayout} component={LoginForm} />
							{/* https://github.com/aws-amplify/amplify-console/issues/97 */}
							<GuestOnlyRoute exact path='/authenticatedUrl/' layout={AuthLayout} component={LoginForm} />
							<ProtectedRoute exact path='/logout' layout={SpinnerLayout} component={Logout} />
							<ProtectedRoute exact path='/' layout={SummaryLayout} component={PatientsListPage} ContactUsEnabled />
							<ProtectedRoute
								exact
								path='/errorCheck'
								layout={SummaryLayout}
								component={() => {
									throw Error('Test error');
								}}
							/>
							<ExternalRedirectRoute
								layout={EmptyLayout}
								component={EmptyDiv}
								exact
								path='/athena'
								url='https://chrome.google.com/webstore/detail/navina-burnout-research-t/plhceajggdihcnffppedpfjpflpmdakh?hl=en-GB&authuser=2'
							/>
							<ExternalRedirectRoute
								layout={EmptyLayout}
								component={EmptyDiv}
								exact
								path='/ecw'
								url='https://chrome.google.com/webstore/detail/navina-addon/clkcfpclllbaegehjacadbpejiocgkii?hl=en-GB&authuser=2'
							/>
							<ProtectedRoute layout={EmptyLayout} exact path='/qs' component={QuicksightPage} />
							<ProtectedRoute layout={EmptyLayout} exact path='/analytics' component={QuicksightPage} />
							<ProtectedRoute layout={EmptyLayout} exact path='/postVisit' component={PostVisitPage} />
							<ProtectedRoute layout={EmptyLayout} exact path='/quality' component={Quality} ContactUsEnabled />
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/quality-workspace'
								component={Quality}
								ContactUsEnabled
							/>
							<ProtectedRoute layout={EmptyLayout} exact path='/quality-satisfaction-rate' component={Quality} />
							<ProtectedRoute layout={EmptyLayout} exact path='/files' component={FilesManager} ContactUsEnabled />
							<ProtectedRoute layout={EmptyLayout} exact path='/quality-fit' component={FileIngestionToolPage} />
							<ProtectedRoute layout={EmptyLayout} exact path='/quality-fit-templates' component={FITTemplatesPage} />
							<ProtectedRoute layout={EmptyLayout} exact path='/quality-fit/add-template' component={AddTemplate} />
							<ProtectedRoute layout={EmptyLayout} exact path='/antd-demo' component={AntdDemo} />
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/summary/unity-match-summary-not-found'
								component={NotFoundPage}
								hideActionButton={false}
							/>
							<ProtectedRoute layout={EmptyLayout} exact path='/:summaryId' isSummaryView component={SummaryPage} />
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/overlay/:summaryId'
								isSummaryView
								component={SummaryPage}
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/overlay/hcc/:summaryId'
								isSummaryView
								component={SummaryPage}
								overlayPageName='hcc'
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/overlay/clinical/:summaryId'
								isSummaryView
								component={SummaryPage}
								overlayPageName='portrait'
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/overlay/portrait/:summaryId'
								isSummaryView
								component={SummaryPage}
								overlayPageName='portrait'
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/overlay/documents/:summaryId'
								isSummaryView
								component={SummaryPage}
								overlayPageName='documents'
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/overlay/alerts/:summaryId'
								isSummaryView
								component={SummaryPage}
								overlayPageName='alerts'
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/doc/:documentId'
								isSummaryView
								component={DocumentViewerFullPage}
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/document/:documentId'
								isSummaryView
								component={DocumentViewerFullPage}
							/>
							<AnyoneRoute layout={EmptyLayout} component={NotFoundMessage} />
						</Switch>
					</NavinaDataProvider>
				</Router>
			</ThemeProvider>
		</ErrorBoundary>
	);
}
